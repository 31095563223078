<template>
  <van-overlay :show="show">

    <!-- 微信 -->
    <div v-if="popupData.id=='23'" class="noticepopup-wrapper" @click.stop>
      <div class="noticepopup-wrapper-box" @click="gotoWeixin">
        <div class="close"></div>
        <img :src="popupData.image_url" alt="">
      </div>
    </div>
    <!-- 银行卡 -->
    <div v-else-if="popupData.id=='22'" class="noticepopup-wrapper" @click.stop>
      <div class="noticepopup-wrapper-box" @click="gotoBanks">
        <div class="close"></div>
        <img :src="popupData.image_url" alt="">
      </div>
    </div>
    <!-- 其他 -->
    <div v-else class="noticepopup-wrapper" @click.stop>
      <div class="noticepopup-wrapper-box" @click="showPops">
        <div class="close" style="text-align: right;"><img style="width:24px;" src="@/assets/images/x.png" alt=""></div>
        <img :src="popupData.image_url" alt="">
      </div>
      <div class="primary-button" style="width:80%" @click="gotoConfirm(popupData)" v-if="popupData.btn_text">{{ popupData.btn_text?popupData.btn_text:'确定' }}</div>
    </div>
  </van-overlay>
</template>
<script lang='ts' setup>
import {ref,defineProps,onMounted,watch,computed} from 'vue'
import homeService from '@/services/home'
import bankService from '@/services/bank'
import useCommon from '@/composables/useCommon'
import {useStore} from 'vuex'

const store = useStore()

const {JumpTo} = useCommon()
const {getBankCards} = bankService

const {setReadPopup} = homeService
const show = ref(false)
const props = defineProps({
  popup: {
    type: Array,
  }
})

const hasAccounts = ref(false)

const popupData = ref({})
const times = ref(0)

watch(()=>props.popup, (newVal) => {
  times.value = props.popup?.length
  if (times.value) {
    showPops()
  } 
})
const is_weixin = () => {
  if (/(micromessenger)/i.test(navigator.userAgent)) {
    return true
  } else {
    return false
  }
}
const gotoBanks = () => {
  showPops()
  JumpTo('BankList')
}
const gotoConfirm = (val) => {
  showPops()
  if (val.redirect_url) {
    window.open(val.redirect_url, '_blank')
  }
}
const getBanks = async () => {
  try {
    const {data} = await getBankCards()
    // payment_sign_status = 2 表示签约完成
    let cards = data.cards.filter(v=>v.payment_sign_status==2)

    hasAccounts.value = cards.length > 0

  } catch(err) {
    console.log('err', err);
    
  }
}
const showPops = async () => {
  if (times.value > 0) {
    
    let index = props.popup.length - times.value
    popupData.value = times.value ? props.popup[index] : {}

    times.value -= 1
    readNext()

    if (popupData.value.id=='22') {
      await getBanks()
      if (hasAccounts.value) {
        showPops()
        return
      }
    } else if (popupData.value.id=='23') {
      if (store.state.user.user.open_id!=='' || !is_weixin()) {
        showPops()
        return
      }
    }

    show.value = true
  } else {
    show.value = false
  }
}
const readNext = async () => {
  try {
    await setReadPopup(popupData.value.id)
  }catch(err){
    console.log('err',err)
  }
}

const gotoWeixin = () => {
  showPops()
  const appId = store.state.common.appId
  let redirectUrl = window.location.origin + '/home'
  const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent(redirectUrl)}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
  window.open(url)
}

</script>
<style lang='scss'>
.noticepopup-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  .noticepopup-wrapper-box {
    width: 80%;
    position: relative;
    .close {
      position: absolute;
      right: 0;
      top: 0;
      width: 15%;
      height: 25%;
    }
    &>img {
      width: 100%;
    }
  }
}
</style>