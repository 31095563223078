import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/x.png'


const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

import {ref,onMounted,watch,computed} from 'vue'
import homeService from '@/services/home'
import bankService from '@/services/bank'
import useCommon from '@/composables/useCommon'
import {useStore} from 'vuex'


export default /*@__PURE__*/_defineComponent({
  __name: 'PopupNotice',
  props: {
  popup: {
    type: Array,
  }
},
  setup(__props) {

const store = useStore()

const {JumpTo} = useCommon()
const {getBankCards} = bankService

const {setReadPopup} = homeService
const show = ref(false)
const props = __props

const hasAccounts = ref(false)

const popupData = ref({})
const times = ref(0)

watch(()=>props.popup, (newVal) => {
  times.value = props.popup?.length
  if (times.value) {
    showPops()
  } 
})
const is_weixin = () => {
  if (/(micromessenger)/i.test(navigator.userAgent)) {
    return true
  } else {
    return false
  }
}
const gotoBanks = () => {
  showPops()
  JumpTo('BankList')
}
const gotoConfirm = (val) => {
  showPops()
  if (val.redirect_url) {
    window.open(val.redirect_url, '_blank')
  }
}
const getBanks = async () => {
  try {
    const {data} = await getBankCards()
    // payment_sign_status = 2 表示签约完成
    let cards = data.cards.filter(v=>v.payment_sign_status==2)

    hasAccounts.value = cards.length > 0

  } catch(err) {
    console.log('err', err);
    
  }
}
const showPops = async () => {
  if (times.value > 0) {
    
    let index = props.popup.length - times.value
    popupData.value = times.value ? props.popup[index] : {}

    times.value -= 1
    readNext()

    if (popupData.value.id=='22') {
      await getBanks()
      if (hasAccounts.value) {
        showPops()
        return
      }
    } else if (popupData.value.id=='23') {
      if (store.state.user.user.open_id!=='' || !is_weixin()) {
        showPops()
        return
      }
    }

    show.value = true
  } else {
    show.value = false
  }
}
const readNext = async () => {
  try {
    await setReadPopup(popupData.value.id)
  }catch(err){
    console.log('err',err)
  }
}

const gotoWeixin = () => {
  showPops()
  const appId = store.state.common.appId
  let redirectUrl = window.location.origin + '/home'
  const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent(redirectUrl)}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
  window.open(url)
}


return (_ctx: any,_cache: any) => {
  const _component_van_overlay = _resolveComponent("van-overlay")!

  return (_openBlock(), _createBlock(_component_van_overlay, { show: show.value }, {
    default: _withCtx(() => [
      (popupData.value.id=='23')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "noticepopup-wrapper",
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
          }, [
            _createElementVNode("div", {
              class: "noticepopup-wrapper-box",
              onClick: gotoWeixin
            }, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "close" }, null, -1)),
              _createElementVNode("img", {
                src: popupData.value.image_url,
                alt: ""
              }, null, 8, _hoisted_1)
            ])
          ]))
        : (popupData.value.id=='22')
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "noticepopup-wrapper",
              onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
            }, [
              _createElementVNode("div", {
                class: "noticepopup-wrapper-box",
                onClick: gotoBanks
              }, [
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "close" }, null, -1)),
                _createElementVNode("img", {
                  src: popupData.value.image_url,
                  alt: ""
                }, null, 8, _hoisted_2)
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: "noticepopup-wrapper",
              onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
            }, [
              _createElementVNode("div", {
                class: "noticepopup-wrapper-box",
                onClick: showPops
              }, [
                _cache[6] || (_cache[6] = _createElementVNode("div", {
                  class: "close",
                  style: {"text-align":"right"}
                }, [
                  _createElementVNode("img", {
                    style: {"width":"24px"},
                    src: _imports_0,
                    alt: ""
                  })
                ], -1)),
                _createElementVNode("img", {
                  src: popupData.value.image_url,
                  alt: ""
                }, null, 8, _hoisted_3)
              ]),
              (popupData.value.btn_text)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "primary-button",
                    style: {"width":"80%"},
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (gotoConfirm(popupData.value)))
                  }, _toDisplayString(popupData.value.btn_text?popupData.value.btn_text:'确定'), 1))
                : _createCommentVNode("", true)
            ]))
    ]),
    _: 1
  }, 8, ["show"]))
}
}

})